import { productApi } from '../../api/productApi'
export const GET_LIST_PRODUCTS = 'GET_LIST_PRODUCTS'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'

export const getListProductsAction = () => async (dispatch) => {
  try {
    const response = await productApi.getListProducts().catch((error) => console.log(error))
    if (response) {
      dispatch({ type: GET_LIST_PRODUCTS, payload: response.data.data.result })
    }
  } catch (error) {
    console.log(error)
  }
}
export const deleteProductAction = (arrayId, noti) => async (dispatch) => {
  try {
    const response = await productApi.deleteProduct(arrayId).catch((error) => console.log(error))
    if (response) {
      noti()
      dispatch(getListProductsAction())
    }
  } catch (error) {
    console.log(error)
  }
}
