import { Avatar, Dropdown, Layout, Menu, Space } from 'antd'

import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { logoutAction } from '../../../redux/auth/actions'

const { Header } = Layout

export const HeaderAdmin = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const handleLogout = () => {
    dispatch(logoutAction(history))
  }
  const menu = (
    <Menu>
      <Menu.Item onClick={handleLogout}>Đăng xuất</Menu.Item>
    </Menu>
  )

  return (
    <Header className="header">
      <Space size={12} align="center">
        <Dropdown overlay={menu} trigger={['click']}>
          <Avatar style={{ backgroundColor: 'orange', cursor: 'pointer' }}>A</Avatar>
        </Dropdown>
        Admin
      </Space>
    </Header>
  )
}
