export const GET_LIST_CATALOGS = 'GET_LIST_CATALOGS'
export const DELETE_CATALOG = 'DELETE_CATALOG'
import { catalogsApi } from '../../api/catalogApi'
export const getListCatalogsAction = () => async (dispatch) => {
  try {
    const response = await catalogsApi.getListCatalogs()
    if (response) {
      dispatch({ type: GET_LIST_CATALOGS, payload: response.data.data.result })
    }
  } catch (error) {
    console.log(error)
  }
}
export const deleteCatalogAction = (id, noti) => async (dispatch) => {
  try {
    const response = await catalogsApi.deleteCatalog(id).catch((error) => console.log(error))
    if (response) {
      noti()
      dispatch(getListCatalogsAction())
    }
  } catch (error) {
    console.log(error)
  }
}
