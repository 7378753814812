import { axiosClient, axiosMultipart } from './axiosClient'
export const catalogsApi = {
  getListCatalogs: async () => {
    const response = axiosClient.get('/Catalog/getListCatalog')
    return response
  },
  createCatalog: async (data) => {
    const response = axiosMultipart.post('/Catalog/insert', data)
    return response
  },
  updateCatalog: async (data) => {
    const response = axiosMultipart.put('/Catalog/update', data)
    return response
  },
  getCatalogById: async (id) => {
    const response = axiosClient.get(`/Catalog/getListCatalog?id=${id}`)
    return response
  },
  deleteCatalog: async (arrayId) => {
    const data = { deleteId: arrayId }
    const response = axiosClient.delete('/Catalog/deleteCatalog', { data: data })
    return response
  }
}
