import axios from 'axios'
import { getToken } from '../helpers/auth'

const HEADERS = {
  'Content-type': 'application/json',
  'Access-Control-Allow-Origin': '*'
}
const HEADERS_MULTIPART = {
  ...HEADERS,
  'Content-type': 'multipart/form-data; boundary=something'
}
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: HEADERS
})
const axiosMultipart = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: HEADERS_MULTIPART
})
axiosClient.interceptors.request.use(
  function (config) {
    const token = getToken()
    if (token) {
      HEADERS['Authorization'] = `Bearer ${token}`
      config.headers = HEADERS
    } else config.headers = HEADERS
    return config
  },
  function (error) {
    console.log(error)
    return Promise.reject(error)
  }
)
axiosMultipart.interceptors.request.use(
  function (config) {
    const token = getToken()
    if (token) {
      HEADERS_MULTIPART['Authorization'] = `Bearer ${token}`
      config.headers = HEADERS_MULTIPART
    } else config.headers = HEADERS_MULTIPART
    return config
  },
  function (error) {
    console.log(error)
    return Promise.reject(error)
  }
)

axiosMultipart.interceptors.response.use(
  function (response) {
    return response.data
  },
  function (error) {
    console.log(error.response)
    return Promise.reject(error)
  }
)
export { axiosClient, axiosMultipart }
