import { axiosClient, axiosMultipart } from './axiosClient'

export const imagesApi = {
  getImages: async () => {
    const response = axiosClient.get('/image')
    return response
  },
  addImages: async (data) => {
    const response = axiosMultipart.post('/image', data)
    return response
  },
  deleteImages: async (arrayId) => {
    const data = { imageId: arrayId }
    const response = axiosClient.delete('/image', { data })
    return response
  }
}
