import {
  AppstoreOutlined,
  AuditOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileTextOutlined,
  MailOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  ShoppingCartOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
const { Sider } = Layout
const { SubMenu } = Menu
function AppSider() {
  return (
    <Sider className="sider" breakpoint="md" collapsedWidth="0">
      <Menu inlineCollapsed={true} mode="inline">
        <SubMenu key="sub1" icon={<MailOutlined />} title="Sản phẩm">
          <Menu.Item key="1">
            <Link to="/product/all">Tất cả sản phẩm</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/product/create">Tạo sản phẩm mới</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Loại hàng">
          <Menu.Item key="3">
            <Link to="/category/all"> Tất cả loại hàng</Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/category/create">Tạo loại hàng mới</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub4" icon={<ProfileOutlined />} title="Danh mục">
          <Menu.Item key="5">
            <Link to="/catalog/all"> Tất cả danh mục</Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link to="/catalog/create">Tạo danh mục</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="7" icon={<UserOutlined />}>
          <Link to="/customer-list">Người dùng</Link>
        </Menu.Item>
        <Menu.Item key="8" icon={<ShoppingCartOutlined />}>
          <Link to="/bill-list">Đơn hàng</Link>
        </Menu.Item>
        <Menu.Item key="9" icon={<ProfileOutlined />}>
          <Link to="/edit-slideshow">Ảnh bìa</Link>
        </Menu.Item>
        <Menu.Item key="10" icon={<FileImageOutlined />}>
          <Link to="/product-images">Ảnh sản phẩm</Link>
        </Menu.Item>
        <Menu.Item key="11" icon={<FileExcelOutlined />}>
          <Link to="/import">Nhập</Link>
        </Menu.Item>
        <Menu.Item key="12" icon={<FileTextOutlined />}>
          <Link to="/articles">Bài viết</Link>
        </Menu.Item>
        <Menu.Item key="13" icon={<QuestionCircleOutlined />}>
          <Link to="/faqs">FAQS</Link>
        </Menu.Item>
        <Menu.Item key="14" icon={<AuditOutlined />}>
          <Link to="/agency">Agency</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  )
}

export default AppSider
