import { imagesApi } from '../../api/imagesApi'
export const GET_LIST_IMAGES = 'GET_LIST_IMAGES'
export const DELETE_IMAGE = 'DELETE_IMAGE'

export const getListImagesAction = () => async (dispatch) => {
  try {
    const response = await imagesApi.getImages().catch((error) => console.log(error))
    if (response) {
      dispatch({ type: GET_LIST_IMAGES, payload: response.data.data.data })
    }
  } catch (error) {
    console.log(error)
  }
}
export const deleteImagesAction = (arrayId, noti) => async (dispatch) => {
  try {
    const response = await imagesApi.deleteImages(arrayId).catch((error) => console.log(error))
    if (response) {
      noti()
      dispatch(getListImagesAction())
    }
  } catch (error) {
    console.log(error)
  }
}
export const addImagesAction = (files, noti) => async (dispatch) => {
  try {
    const response = await imagesApi.addImages(files).catch((error) => console.log(error))
    if (response) {
      noti()
      dispatch(getListImagesAction())
    }
  } catch (error) {
    console.log(error)
  }
}
