import { GET_LIST_AGENCY } from './actions'
const initialState = {
  agencyList: null
}
const agencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_AGENCY:
      return { ...state, agencyList: action.payload }
    default:
      return state
  }
}
export default agencyReducer
