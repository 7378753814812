import { axiosClient, axiosMultipart } from './axiosClient'
export const productApi = {
  getListProducts: async () => {
    const response = axiosClient.get('/product/getListProduct')
    return response
  },
  createProduct: async (data) => {
    const response = axiosMultipart.post('/product/insert', data)
    return response
  },
  getProductById: async (id) => {
    const response = axiosClient.get(`/product/getListProduct?id=${id}`)
    return response
  },
  updateProduct: async (data) => {
    const response = axiosMultipart.put('/product/update', data)
    return response
  },
  deleteProduct: async (arrayId) => {
    const data = { deleteId: arrayId }
    const response = axiosClient.delete('/product/deleteProduct', { data: data })
    return response
  },
  importProducts: async (data) => {
    const response = axiosMultipart.post('/product/insert-many-product', data)
    return response
  }
}
