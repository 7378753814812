import React from 'react'
import { Redirect, Route } from 'react-router'
import { getToken } from '@Helpers/auth'

function PrivateRoute({ ...props }) {
  const token = getToken()
  if (!token) return <Redirect to="/login" />
  return <Route {...props} />
}

export default PrivateRoute
