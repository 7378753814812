import { combineReducers, createStore, applyMiddleware } from 'redux'
import authReducer from '../redux/auth/reducer'
import thunkMiddleware from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage'
import productReducer from '../redux/product/reducer.'
import categoryReducer from '../redux/category/reducer'
import catalogReducer from '../redux/catalog/reducer'
import imagesReducer from '../redux/images/reducer'
import articleReducer from '../redux/article/reducer'
import faqReducer from '../redux/faqs/reducer'
import agencyReducer from '../redux/agency/reducer'

const rootReducer = combineReducers({
  auth: authReducer,
  product: productReducer,
  category: categoryReducer,
  catalog: catalogReducer,
  images: imagesReducer,
  article: articleReducer,
  faq: faqReducer,
  agency: agencyReducer
})

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}
const persistedReducers = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducers, bindMiddleware([thunkMiddleware]))
const persistor = persistStore(store)
export { store, persistor }
