import { DELETE_CATEGORY, GET_LIST_CATEGORIES } from './actions'
const initialState = {
  categoryList: null
}
const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_CATEGORIES:
      return { ...state, categoryList: action.payload }
    case DELETE_CATEGORY:
      return { ...state }
    default:
      return state
  }
}
export default categoryReducer
