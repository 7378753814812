import React from 'react'
import { Layout, Divider } from 'antd'
import { HeaderAdmin } from '../Layout/Header'
import AppSider from './Sider'

import PropTypes from 'prop-types'
const { Content } = Layout
function AppLayout({ children }) {
  return (
    <Layout className="layout">
      <HeaderAdmin />
      <Divider style={{ margin: 0 }} />
      <Layout>
        <AppSider />
        <Content
          style={{
            padding: 24,
            paddingTop: 0,
            margin: 0
          }}>
          {children}
        </Content>
      </Layout>
      <Divider style={{ margin: 0 }} />
    </Layout>
  )
}

AppLayout.propTypes = {
  children: PropTypes.element
}
export default AppLayout
