const ISSERVER = typeof window === 'undefined'
import jwt from 'jsonwebtoken'

const setToken = (token) => {
  localStorage.setItem('token', token)
}
const getToken = () => {
  if (!ISSERVER) return localStorage.getItem('token') || null
  return null
}
const verifyToken = (token) => {
  try {
    const decode = jwt.verify(token, process.env.REACT_APP_JWT_SECRET)
    if (decode) return decode
  } catch (error) {
    console.log(error)
    return null
  }
}
const clearInfo = () => {
  localStorage.clear()
}
export { setToken, getToken, verifyToken, clearInfo }
