import { LOGIN, LOGIN_ERROR, LOGOUT, REGISTER, REGISTER_ERROR } from './actions'
const initialState = {
  authData: null,
  error: null
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER:
    case LOGIN:
      return { ...state, authData: action.payload, error: null }
    case LOGOUT:
      return {
        ...state,
        authData: null,
        error: null
      }
    case REGISTER_ERROR:
    case LOGIN_ERROR:
      return { ...state, error: action.payload }
    default:
      return state
  }
}
export default authReducer
