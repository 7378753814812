import { categoriesApi } from '../../api/categoryApi'
export const GET_LIST_CATEGORIES = 'GET_LIST_CATEGORIES'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'

export const getListCategoriesAction = () => async (dispatch) => {
  try {
    const response = await categoriesApi.getListCategories()
    if (response) {
      dispatch({ type: GET_LIST_CATEGORIES, payload: response.data.data.result })
    }
  } catch (error) {
    console.log(error)
  }
}
export const deleteCategoryAction = (id, noti) => async (dispatch) => {
  try {
    const response = await categoriesApi.deleteCategory(id).catch((error) => console.log(error))
    if (response) {
      noti()
      dispatch(getListCategoriesAction())
    }
  } catch (error) {
    console.log(error)
  }
}
