import { faqsApi } from '../../api/faqsApi'

export const GET_LIST_FAQS = 'GET_LIST_FAQS'
export const getListFaqs = () => async (dispatch) => {
  try {
    const response = await faqsApi.getListFaqs()
    dispatch({ type: GET_LIST_FAQS, payload: response.data.data.result })
  } catch (error) {
    console.log(error)
  }
}
export const deleteFaqsAction = (arrayId, noti) => async (dispatch) => {
  const data = { faqIds: arrayId }
  try {
    const response = await faqsApi.deleteFaq(data)
    if (response) {
      noti()
      dispatch(getListFaqs())
    }
  } catch (error) {
    console.log(error)
  }
}
