import { GET_LIST_FAQS } from './actions'
const initialState = {
  faqList: null
}
const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_FAQS:
      return { ...state, faqList: action.payload }
    default:
      return state
  }
}
export default faqReducer
