import { axiosClient } from './axiosClient'
export const agencyApi = {
  getListAgencies: async () => {
    const response = await axiosClient.get('/Agency')
    return response
  },
  updateAgency: async (data) => {
    const response = await axiosClient.put('/Agency', data)
    return response
  },
  deleteAgencies: async (data) => {
    const response = await axiosClient.delete('/Agency', { data })
    return response
  }
}
