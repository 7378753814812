import { axiosClient } from './axiosClient'

export const authApi = {
  login: async (data) => {
    const response = await axiosClient.post('/auth/login-admin', data)
    return response
  },
  register: async (data) => {
    const response = await axiosClient.post('/auth/logout-admin', data)
    return response
  }
}
