import { articleAPi } from '../../api/articleApi'

export const GET_LIST_ARTICLES = 'GET_LIST_ARTICLES'
export const getListArticlesAction = () => async (dispatch) => {
  try {
    const response = await articleAPi.getListArticles()
    dispatch({ type: GET_LIST_ARTICLES, payload: response.data.data.result })
  } catch (error) {
    console.log(error)
  }
}
export const deleteArticleAction = (arrayId, noti) => async (dispatch) => {
  const data = { deleteId: arrayId }
  try {
    const response = await articleAPi.deleteArticle(data)
    if (response) {
      noti()
      dispatch(getListArticlesAction())
    }
  } catch (error) {
    console.log(error)
  }
}
