import { DELETE_CATALOG, GET_LIST_CATALOGS } from './actions'
const initialState = {
  catalogList: null
}
const catalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_CATALOGS:
      return { ...state, catalogList: action.payload }
    case DELETE_CATALOG:
      return { ...state }
    default:
      return state
  }
}
export default catalogReducer
