import { GET_LIST_ARTICLES } from './actions'

const initialState = {
  articlesList: null
}
const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_ARTICLES:
      return { ...state, articlesList: action.payload }
    default:
      return state
  }
}
export default articleReducer
