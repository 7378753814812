import { axiosClient, axiosMultipart } from './axiosClient'
export const articleAPi = {
  getListArticles: async () => {
    const response = axiosClient.get('/Descriptions/getListDescriptions')
    return response
  },
  addArticle: async (data) => {
    const response = axiosMultipart.post('/Descriptions/insert', data)
    return response
  },
  updateArticle: async (data) => {
    const response = axiosMultipart.put('/Descriptions/update', data)
    return response
  },
  deleteArticle: async (arrayId) => {
    const response = await axiosClient.delete('/Descriptions/deleteDescriptions', { data: arrayId })
    return response
  },
  getArticleById: async (id) => {
    const response = await axiosClient.get(`/Descriptions/getListDescriptions?id=${id}`)
    return response
  }
}
