import React from 'react'
import ReactDOM from 'react-dom'
import '@Styles/main.scss'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './store'
import { Spin } from 'antd'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<Spin />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
