import { axiosClient, axiosMultipart } from './axiosClient'
export const categoriesApi = {
  getListCategories: async () => {
    const response = axiosClient.get('/Categories/getListCategories')
    return response
  },
  createCategory: async (data) => {
    const response = axiosMultipart.post('/Categories/insert', data)
    return response
  },
  updateCategory: async (data) => {
    const response = axiosMultipart.put('/Categories/update', data)
    return response
  },
  deleteCategory: async (arrayId) => {
    const data = { deleteId: arrayId }
    const response = axiosClient.delete('/Categories/deleteCategories', { data: data })
    return response
  },
  getCategoryById: async (id) => {
    const response = axiosClient.get(`/Categories/getListCategories?id=${id}`)
    return response
  }
}
