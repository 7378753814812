import { DELETE_IMAGE, GET_LIST_IMAGES } from './actions'
const initialState = {
  imagesList: null
}
const imagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_IMAGES:
      return { ...state, imagesList: action.payload }
    case DELETE_IMAGE:
      return { ...state }
    default:
      return state
  }
}
export default imagesReducer
