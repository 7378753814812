/* eslint-disable react/jsx-key */
import React from 'react'
import './App.css'
import 'antd/dist/antd.css'
import { Redirect, Route, Switch } from 'react-router-dom'
import AppLayout from './components/Layout'
import { privateRoutes, routes } from '@Pages/routes'
import PrivateRoute from './pages/PrivateRoute'

function App() {
  return (
    <Switch>
      {routes.map(({ component: Component, ...rest }) => (
        <Route
          render={(props) => (
            <React.Suspense fallback="Loading !">
              <Component {...props} />
            </React.Suspense>
          )}
          {...rest}
        />
      ))}
      <PrivateRoute path="/">
        <AppLayout>
          <Switch>
            {privateRoutes.map(({ component: Component, ...rest }) => (
              <Route
                render={(props) => (
                  <React.Suspense fallback="Loading !">
                    <Component {...props} />
                  </React.Suspense>
                )}
                {...rest}
              />
            ))}
          </Switch>
        </AppLayout>
      </PrivateRoute>
      <Redirect to="/404" />
    </Switch>
  )
}

export default App
