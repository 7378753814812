import { axiosClient } from './axiosClient'
export const faqsApi = {
  getListFaqs: async () => {
    const response = await axiosClient.get('/faq')
    return response
  },
  createFaq: async (data) => {
    const response = await axiosClient.post('/faq', data)
    return response
  },
  updateFaq: async (data) => {
    const response = await axiosClient.put('/faq', data)
    return response
  },
  deleteFaq: async (arrayId) => {
    const response = await axiosClient.delete('/faq', { data: arrayId })
    return response
  },
  getListFaqTypes: async () => {
    const response = await axiosClient.get('/faq/faq-type')
    return response
  },
  getFaqById: async (id) => {
    const response = await axiosClient.get(`/faq?faqId=${id}`)
    return response
  }
}
