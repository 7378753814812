import { DELETE_PRODUCT, GET_LIST_PRODUCTS } from './actions'
const initialState = {
  productList: null
}
const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_PRODUCTS:
      return { ...state, productList: action.payload }
    case DELETE_PRODUCT:
      return { ...state }
    default:
      return state
  }
}
export default productReducer
