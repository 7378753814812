import { clearInfo, setToken, verifyToken } from '../../helpers/auth'
import { authApi } from '../../api/authApi'
export const LOGIN = 'LOGIN'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT = 'LOGOUT'
export const REGISTER = 'REGISTER'
export const REGISTER_ERROR = 'REGISTER_ERROR'

const loginAction = (data, history) => async (dispatch) => {
  try {
    const response = await authApi.login(data).catch((error) => dispatch({ type: LOGIN_ERROR, payload: error.response.data.message }))
    if (response) {
      setToken(response.data.data.lastToken)
      const decode = verifyToken(response.data.data.lastToken)
      dispatch({ type: LOGIN, payload: decode })
      console.log('hello')
      history.replace('/')
    }
  } catch (error) {
    console.log(error)
  }
}

const registerAction = (data, history) => async (dispatch) => {
  try {
    const response = await authApi.register(data).catch((error) => dispatch({ type: REGISTER_ERROR, payload: error.response.data.message }))
    if (response) {
      setToken(response.data.data.lastToken)
      const decode = verifyToken(response.data.data.lastToken)
      dispatch({ type: REGISTER, payload: decode })
      history.push('/')
    }
  } catch (error) {
    console.log(error)
  }
}
const logoutAction = (history) => async (dispatch) => {
  try {
    clearInfo()
    dispatch({ type: LOGOUT })
    history.push('/login')
  } catch (error) {
    console.log(error)
  }
}
export { loginAction, registerAction, logoutAction }
