import { lazy } from 'react'

const Home = lazy(() => import('./Home'))
const Login = lazy(() => import('./Login'))
const Register = lazy(() => import('./Register'))
const NotFound = lazy(() => import('./NotFound'))
const CreateProduct = lazy(() => import('./Product/CreateProduct'))
const CreateCategory = lazy(() => import('./Product/CreateCategory'))
const CreateCatalog = lazy(() => import('./Product/CreateCatalog'))
const AllProduct = lazy(() => import('./Product/AllProduct'))
const BillList = lazy(() => import('./BillList'))
const CustomerList = lazy(() => import('./CustomerList'))
const BillDetail = lazy(() => import('./BillDetail'))
const EditSlideShow = lazy(() => import('./EditSlideShow'))
const CatalogList = lazy(() => import('./Product/AllCatalog'))
const CategoryList = lazy(() => import('./Product/AllCategory'))
const ProductImages = lazy(() => import('./ProductImages'))
const Import = lazy(() => import('./Import'))
const PageEditor = lazy(() => import('./PageEditor'))
const EditArticle = lazy(() => import('./PageEditor/EditArticle'))
const FAQS = lazy(() => import('./FAQS'))
const EditFaq = lazy(() => import('./FAQS/EditFaq'))
const Agency = lazy(() => import('./Agency'))
const privateRoutes = [
  {
    path: '/404',
    key: 'NOT_FOUND',
    exact: true,
    public: true,
    component: NotFound
  },
  {
    path: '/',
    key: 'HOME',
    exact: true,
    public: true,
    component: Home
  },
  {
    path: '/register',
    key: 'REGISTER',
    exact: true,
    public: true,
    component: Register
  },
  {
    path: '/catalog/create',
    key: 'CATALOG',
    exact: true,
    public: true,
    component: CreateCatalog
  },
  {
    path: '/catalog/edit/:id',
    key: 'CATALOG',
    exact: true,
    public: true,
    component: CreateCatalog
  },
  {
    path: '/category/create',
    key: 'CATEGORY',
    exact: true,
    public: true,
    component: CreateCategory
  },
  {
    path: '/category/edit/:id',
    key: 'CATEGORY',
    exact: true,
    public: true,
    component: CreateCategory
  },
  {
    path: '/product/create',
    key: 'PRODUCT',
    exact: true,
    public: true,
    component: CreateProduct
  },
  {
    path: '/product/edit/:id',
    key: 'PRODUCT',
    exact: true,
    public: true,
    component: CreateProduct
  },
  {
    path: '/product/all',
    key: 'PRODUCT',
    exact: true,
    public: true,
    component: AllProduct
  },
  {
    path: '/bill-list',
    key: 'BILL',
    exact: true,
    public: true,
    component: BillList
  },
  {
    path: '/customer-list',
    key: 'CUSTOMER',
    exact: true,
    public: true,
    component: CustomerList
  },
  {
    path: '/bill-detail/:id',
    key: 'BILL-DETAIL',
    exact: true,
    public: true,
    component: BillDetail
  },
  {
    path: '/catalog/all',
    key: 'CATALOG-LIST',
    exact: true,
    public: true,
    component: CatalogList
  },
  {
    path: '/category/all',
    key: 'CATEGORY-LIST',
    exact: true,
    public: true,
    component: CategoryList
  },
  {
    path: '/edit-slideshow',
    key: 'SLIDESHOW',
    exact: true,
    public: true,
    component: EditSlideShow
  },
  {
    path: '/product-images',
    key: 'images',
    exact: true,
    public: true,
    component: ProductImages
  },
  {
    path: '/import',
    key: 'import',
    exact: true,
    public: true,
    component: Import
  },
  {
    path: '/articles',
    key: 'article',
    exact: true,
    public: true,
    component: PageEditor
  },
  {
    path: '/article-edit/:id',
    key: 'editor',
    exact: true,
    public: true,
    component: EditArticle
  },
  {
    path: '/article-edit',
    key: 'editor-news',
    exact: true,
    public: true,
    component: EditArticle
  },
  {
    path: '/faqs',
    key: 'FAQS',
    exact: true,
    public: true,
    component: FAQS
  },
  {
    path: '/faqs-edit/:id',
    key: 'FAQS-Edit',
    exact: true,
    public: true,
    component: EditFaq
  },
  {
    path: '/faqs-edit',
    key: 'FAQS-Edit',
    exact: true,
    public: true,
    component: EditFaq
  },
  {
    path: '/agency',
    key: 'Agency',
    exact: true,
    public: true,
    component: Agency
  }
]
const routes = [
  {
    path: '/login',
    key: 'LOGIN',
    exact: true,
    public: true,
    component: Login
  }
]
export { routes, privateRoutes }
