import { agencyApi } from '../../api/agencyApi'

export const GET_LIST_AGENCY = 'GET_LIST_AGENCY'
export const getListAgenciesAction = () => async (dispatch) => {
  try {
    const response = await agencyApi.getListAgencies()
    dispatch({ type: GET_LIST_AGENCY, payload: response.data.data.result })
  } catch (error) {
    console.log(error)
  }
}
export const deleteAgencyAction = (arrayId, noti) => async (dispatch) => {
  const data = { agencyIds: arrayId }
  try {
    const response = await agencyApi.deleteAgencies(data)
    if (response) {
      noti()
      dispatch(getListAgenciesAction())
    }
  } catch (error) {
    console.log(error)
  }
}
